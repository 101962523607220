import adminLayout from '@/layouts/Admin'
import httpAxios from '@/utils/http-axios.js'

export default {
    name: 'ColorsFiguresCreate',
    data() {
        return {
            colorFigure: {
                name: null,
                is_amel: 0
            },
            fileFilledImage: null,
            fileBorderImage: null,
            fileColoredImage: null,
            fileAudio: null
        }
    },
    components: {
        adminLayout
    },
    methods: {
        createColorFigure() {
            const self = this
            let formData = new FormData()

            if(self.colorFigure.name) formData.append('name', self.colorFigure.name)
            if(self.fileFilledImage) formData.append('filled_image', self.fileFilledImage)
            if(self.fileBorderImage) formData.append('border_image', self.fileBorderImage)
            if(self.fileColoredImage) formData.append('colored_image', self.fileColoredImage)
            if(self.fileAudio) formData.append('audio', self.fileAudio)
            formData.append('is_amel', self.colorFigure.is_amel)
            
            httpAxios({
                url: self.$backendUrl + '/api/v1/colors-figures',
                method: 'POST',
                data: formData
            }).then(function() {
                self.$router.push({ name: 'admin.colors.figures' })

                self.notifySuccess()
            })
        },
        handleFileFilledImage(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileFilledImage = null
            }

            this.fileFilledImage = files[0]
        },
        handleFileBorderImage(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileBorderImage = null
            }

            this.fileBorderImage = files[0]
        },
        handleFileColoredImage(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileColoredImage = null
            }

            this.fileColoredImage = files[0]
        },
        handleFileAudioUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileAudio = null
            }

            this.fileAudio = files[0]
        }
    }
}